.icon-social {
  width: 2em;
  height: 2em;
  display: block;
}
.icon-social:not(:last-child) {
  margin-right: 1em;
}
.icon-social svg {
  fill: #222;
  display: block;
}
